import React, { ChangeEvent, useState } from "react";


import { useSelector } from "react-redux";
import { allUsersSelector } from "../../app/store/adapters";

import './DashBoardComponentStyles.css';
import { Props } from "../AppPresenter";
import { Box, CircularProgress } from "@mui/material";
import withAuthAndVisibility from "../UserAuth";

const getDatesAndClusters = (email: string, schedules: any, compareDate: any) => {
    const result = [];

    for (const schedule of schedules) {
        const scheduleDate = new Date(schedule.id).setHours(0,0,0,0);

        if (schedule.id >= compareDate) {
        const foundClusters = [];

        for (const key in schedule) {
            if (key.startsWith("cluster") && schedule[key] !== null && schedule[key].includes(email)) {
                foundClusters.push(key);
            }
        }

        if (foundClusters.length > 0) {
            result.push({
            date: schedule.date,
            clusters: foundClusters
            });
        }
        }
    }

    return result;
    };

interface PropsType {
    props: Props,
    updateUser: (id: string, active: boolean, activeDays: number, limitDate: string, userType: string, startDate: string) => void;
  }

const DashBoardComponent: React.FC<PropsType>  = ({ props, updateUser }) => {

    const [name, setName] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [loading, setLoading] = useState(props.loading);
    const [ buttonClicked, setButtonClicked ] = useState("");
    const users = useSelector(allUsersSelector);

    const [ data, setData ] = useState<any[] | null>(users);
  
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedOption('');
        if(e.target.value !== ''){
            let getUsers = users?.filter((user: any) => {
                let fullName = (user.firstName + user.lastName).toLowerCase();
                let email = user.email;
                if (fullName.includes(e.target.value) || email.includes(e.target.value)) {
                    return true;
                } else {
                    return false;
                }
            }) as any[];

            setData(getUsers);
        } else {
            setData(users);
        }
        
        setName(e.target.value);
    }
    const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName('');
        if(e.target.value !== '4'){
            let getUsers = users?.filter((user: any) => {
                let userType = user.userType;
                if (userType === e.target.value) {
                    return true;
                } else {
                    return false;
                }
            }) as any[];

            setData(getUsers);
        } 
        if (e.target.value == '4') {
            let getUsers = users?.filter((user: any) => {
                let userDays = user.activeDays;
                if (userDays === 1) {
                    return true;
                } else {
                    return false;
                }
            }) as any[];

            setData(getUsers);
        } 
        if (e.target.value == '5') {
            
            let getUsers = users?.filter((user: any) => {
                let userDays = user.activeDays;
                let currentDays = remainderDays[user.email];
                if (currentDays === 0 && userDays === 1) {
                    return true;
                } else {
                    return false;
                }
            }) as any[];

            setData(getUsers);
        } 
        if (e.target.value == '6') {
            
            let getUsers = users?.filter((user: any) => {
                let userDays = user.activeDays;
                let currentDays = remainderDays[user.email];
                if (currentDays === 1 && userDays === 1) {
                    return true;
                } else {
                    return false;
                }
            }) as any[];

            setData(getUsers);
        } 
        
        
        setSelectedOption(e.target.value);
    };

    
    
    let activeUsers: any = {};
    let activeDays: any = {};
    let limitDates: any = {};
    let userTypes: any = {};
    let startDates: any = {};


    let remainderDays: any = {};
    let totalDaysUsed: any = {};

    users?.map((user: any) => {

        let hoursSchedule = {};
        let scheduleList = JSON.parse(user?.schedule);
        
        scheduleList?.map((hours: any) => {
            if(new Date(hours.id).setHours(0,0,0,0) >= new Date(user.startDate).setHours(0,0,0,0)){
              hoursSchedule = {
                ...hoursSchedule,
                [new Date(hours.id).getDate()]: hours
              }
            }
          });
        
        let dataArray: any[] = Object.values(hoursSchedule);
        dataArray = dataArray.filter(item => item.table.length > 0);

        let userStartedDate = user.startDate;

        const dateToCompare = new Date(userStartedDate).setHours(0,0,0,0); // The date to compare
        

        const result = getDatesAndClusters(user.email, props.allSchedule, dateToCompare);

        totalDaysUsed = {
            ...totalDaysUsed,
            [user.email]: result
        }

        remainderDays = {
            ...remainderDays,
            [user.email]: dataArray.length
        }
        
        activeUsers = {
            ...activeUsers,
            [user.email]: user.active
        };
        activeDays = {
            ...activeDays,
            [user.email]: user.activeDays
        };
        limitDates = {
            ...limitDates,
            [user.email]: user.limitDate
        };
        userTypes = {
            ...userTypes,
            [user.email]: user.userType
        };
        startDates = {
            ...startDates,
            [user.email]: user.startDate
        };
    })

    const [activeUsersState, setActiveUsersState] = useState(activeUsers);
    const [activeDaysUsersState, setActiveDaysUsersState] = useState(activeDays);
    const [limiteDateUsersState, setLimiteDateUsersState] = useState(limitDates);
    const [usersTypeState, setUsersTypeState] = useState(userTypes);
    const [startDateUsersState, setStartDateUsersState] = useState(startDates);

    const [currentDaysUsersState, setCurrentDaysUsersState] = useState(remainderDays);

    const handleActiveUser = (user: string) => {
        setActiveUsersState({
            ...activeUsersState,
            [user]: !activeUsersState[user]
        })
    }
    const handleActiveDaysUser = (user: string, days: number) => {
        
        setActiveDaysUsersState({
            ...activeDaysUsersState,
            [user]: days
        })
    }

    const handleUserType = (user: string, type: string) => {
        
        setUsersTypeState({
            ...usersTypeState,
            [user]: type
        })
    }

    const handleActiveLimitDateUser = (user: string, date: string) => {
        const dateObject = new Date(`${date}T00:00:00-05:00`);
        
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');

        setLimiteDateUsersState({
            ...limiteDateUsersState,
            [user]: `${year}-${month}-${day}`
        })
    }

    const handleActiveStartDateUser = (user: string, date: string) => {
        const dateObject = new Date(`${date}T00:00:00-05:00`);
        
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');

        setStartDateUsersState({
            ...startDateUsersState,
            [user]: `${year}-${month}-${day}`
        })
    }

    const onSaveUsersData = (id: string) => {
        setButtonClicked(id);
        const userToUpdate: any = users?.filter((user: any) => user.email === id) || [];

        const active = activeUsersState[id];
        const activeDays = activeDaysUsersState[id];
        const limitDate = limiteDateUsersState[id];
        const userType = usersTypeState[id];
        const startDate = startDateUsersState[id];

        updateUser(userToUpdate[0].email, active, activeDays, limitDate, userType, startDate);
        
    }

    React.useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    return (
        <div className='dashboard_container'>
            <form className="dashboard_filter">
                <br />
                <label>
                Buscar Usuario:
                <input className="input_filter" type="text" value={name} onChange={(e) => handleNameChange(e)} required />
                </label>
                <br />
                <fieldset>
                <legend>Tipo de Usuario:</legend>
                <label>
                    <input
                    type="radio"
                    name="option"
                    value="0"
                    checked={selectedOption === '0'}
                    onChange={handleOptionChange}
                    />
                    0
                </label>
                <br />
                <label>
                    <input
                    type="radio"
                    name="option"
                    value="1"
                    checked={selectedOption === '1'}
                    onChange={handleOptionChange}
                    />
                    1
                </label>
                <br />
                <label>
                    <input
                    type="radio"
                    name="option"
                    value="2"
                    checked={selectedOption === '2'}
                    onChange={handleOptionChange}
                    />
                    2
                </label>
                <br />
                <label>
                    <input
                    type="radio"
                    name="option"
                    value="3"
                    checked={selectedOption === '3'}
                    onChange={handleOptionChange}
                    />
                    3
                </label>
                </fieldset>
                <br />
                <fieldset>
                <legend>Usuarios con Cortesía:</legend>
                <label>
                    <input
                    type="radio"
                    name="option"
                    value="4"
                    checked={selectedOption === '4'}
                    onChange={handleOptionChange}
                    />
                    Todos
                </label>
                <br />
                <label>
                    <input
                    type="radio"
                    name="option"
                    value="5"
                    checked={selectedOption === '5'}
                    onChange={handleOptionChange}
                    />
                    Clase no tomada
                </label>
                <br />
                <label>
                    <input
                    type="radio"
                    name="option"
                    value="6"
                    checked={selectedOption === '6'}
                    onChange={handleOptionChange}
                    />
                    Clase tomada
                </label>
                </fieldset>
            </form>
            <div style={{marginLeft: "5%", marginTop: "20px"}}>{`${data?.length} / ${users?.length}`}</div>
            
            <table className="styled-table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Active</th>
                    <th>Active Days</th>
                    <th>Current Days</th>
                    <th>User Type</th>
                    <th>Start Date</th>
                    <th>Limit Date</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((user: any, index: number) => (
                    <tr key={index}>
                    <td>{`${user.firstName} ${user.lastName}`}</td>
                    <td>
                        <input
                        style={{width: "50px"}}
                        type="checkbox"
                        checked={activeUsersState[user.email]}
                        onChange={(e) => handleActiveUser(user.email)}
                        />
                    </td>
                    <td>
                        <input
                        style={{width: "50px"}}
                        type="number"
                        value={activeDaysUsersState[user.email]}
                        onChange={(e) => handleActiveDaysUser(user.email, parseInt(e.target.value))}
                        />
                    </td>
                    <td>
                        <input
                        style={{width: "50px"}}
                        type="number"
                        value={currentDaysUsersState[user.email]}
                        disabled={true}
                        />
                    </td>
                    <td>
                        <input
                        style={{width: "50px"}}
                        type="text"
                        value={usersTypeState[user.email]}
                        onChange={(e) => handleUserType(user.email, e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        value={startDateUsersState[user.email]}
                        onChange={(e) => handleActiveStartDateUser(user.email, e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        value={limiteDateUsersState[user.email]}
                        onChange={(e) => handleActiveLimitDateUser(user.email, e.target.value)}
                        />
                    </td>
                    <td>{user.phoneNumber}</td>
                    <td>{user.email}</td>
                    <td>
                        <button className="signout_button_item" style={{backgroundColor: "white", border: "1px solid white"}} onClick={() => onSaveUsersData(user.email)}>
                            {
                                (!loading) ? "Guardar" : 
                                buttonClicked === user.email ?
                                <Box sx={{ display: 'flex'}}>
                                    <CircularProgress size="14px" style={{color: "gray"}} />
                                </Box> : 'Guardar'
                            }
                        </button>
                    </td>
                    
                    </tr>
                ))}
                </tbody>
            </table>
        </div>



    )
};

export default withAuthAndVisibility(DashBoardComponent);
