import React, { useState } from 'react';
import './ShoppingComponentStyles.css';
import HeaderComponent from '../Header/HeaderComponent';

import { Props } from '../AppPresenter';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

interface PropsType {
  signOut: () => void;
  props: Props;
  onSave: (activeDays: number, limitDate: string, startDate: string, planSelected: string) => void;
}

function formatNumber(number: number) {
    return new Intl.NumberFormat('de-DE').format(number);
}

function countWeekdays(startDate: Date, endDate: Date) {
  
    let count = 0;

    // Iterate through each date from start to end
    while (startDate <= endDate) {
        let day = startDate.getDay();
        // Check if the day is not Sunday (0 is Sunday in JavaScript)
        if (day !== 0) {
            count++;
        }
        // Move to the next day
        startDate.setDate(startDate.getDate() + 1);
    }
    
    return count;
}

const ShoppingComponent : React.FC<PropsType> = ({ signOut, props, onSave }) => {

    const [ planSelected, setPlanSelected ] = useState("");
    const [ openModal, setOpenModal ] = useState(false);
    const [ ticket, setTicket ] = useState("");

    const [loading, setLoading] = useState(props.loading);

    let monthly = (props.allPrices?.[0] as any)?.monthly;
    let valley = (props.allPrices?.[0] as any)?.valley;
    let quarterly = (props.allPrices?.[0] as any)?.quarterly;
    let semester = (props.allPrices?.[0] as any)?.semester;
    let ticket01 = (props.allPrices?.[0] as any)?.ticket01;
    let ticket04 = (props.allPrices?.[0] as any)?.ticket04;
    let ticket08 = (props.allPrices?.[0] as any)?.ticket08;
    let ticket12 = (props.allPrices?.[0] as any)?.ticket12;
    let ticket16 = (props.allPrices?.[0] as any)?.ticket16;

    const plans = [
        {
            name: "Mensual",
            days: 30,
            months: 1,
            price: parseInt(monthly),
            detail: "Válido por 30 días"
        },
        {
            name: "Trimestral",
            days: 30 * 3,
            months: 3,
            price: parseInt(quarterly),
            detail: "Válido por 3 meses"
        },
        {
            name: "Semestral",
            days: 30 * 6,
            months: 6,
            price: parseInt(semester),
            detail: "Válido por 6 meses"
        },
        {
            name: "Horas Valle",
            days: 30,
            months: 1,
            price: parseInt(valley),
            detail: `Válido por 30 días/Lunes a Jueves (8am, 4pm, 8pm), Viernes (7am, 8am, 4pm), Sabados (todos)`
        },
    ];
    const planPerDay = [
        {
            name: "1",
            days: 1,
            months: 0,
            price: parseInt(ticket01),
            detail: ""
        },
        {
            name: "4",
            days: 4,
            months: 0,
            price: parseInt(ticket04),
            detail: ""
        },
        {
            name: "8",
            days: 8,
            months: 0,
            price: parseInt(ticket08),
            detail: ""
        },
        {
            name: "12",
            days: 12,
            months: 0,
            price: parseInt(ticket12),
            detail: ""
        },
        {
            name: "16",
            days: 16,
            months: 0,
            price: parseInt(ticket16),
            detail: ""
        },
        
    ]
    const onSelectPlan = (item: string) => {
        setPlanSelected(item);
    }

    const _handleSavePurchase = () => {
        
        let currentDate = new Date();

        let rangeQuantity = 0;
        let rangeDaysQuantity = 0;

        if (planSelected !== "Tiquetera") {
            let planToPurchase = plans.filter(item => item.name === planSelected);
            rangeQuantity = planToPurchase[0].months;
            rangeDaysQuantity = planToPurchase[0].days;
            currentDate.setMonth(currentDate.getMonth() + rangeQuantity);
            if (currentDate.getDate() !== new Date().getDate()) {
                currentDate.setDate(0);
            }
            rangeDaysQuantity = countWeekdays(new Date(), currentDate);
        } else {
            let planToPurchase = planPerDay.filter(item => item.name === ticket);
            rangeQuantity = planToPurchase[0].days;
            rangeDaysQuantity = planToPurchase[0].days;
            currentDate.setMonth(currentDate.getMonth() + 1);
            if (currentDate.getDate() !== new Date().getDate()) {
                currentDate.setDate(0);
            }
        }

        onSave(rangeDaysQuantity, formatDate(currentDate), formatDate(new Date()), planSelected);
        
    }

    function formatDate(date: Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    React.useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

  return (
    <>
    <HeaderComponent />
    <p className="shopping_title">Aquí puedes solicitar tu membresía. </p>
    <div className='plans_container'>
        {
            plans.map(plan => 
                <div className={planSelected === plan.name ? 'plans_item_selected' : 'plans_item'} onClick={() => onSelectPlan(plan.name)}>
                    <div className='plan_info'>{plan.name}</div>
                    <div className='plan_info'>{"$" + formatNumber(plan.price) + " COP"}</div>
                    <div className='plan_detail'>{plan.detail.split('/')[0]}</div>
                    {
                        plan.detail.split('/')[1] &&
                        <div className='plan_detail'>{plan.detail.split('/')[1]}</div>

                    }
                </div>
            )
        }
        <div className={planSelected === "Tiquetera" ? 'plans_item_selected' : 'plans_item'} onClick={() => setOpenModal(true)}>
            <div className='plan_info'>Tiquetera</div>
            {
                ticket ?
                <>
                    <div>{"Clases: " + ticket}</div>
                    <div>{/*"$" + formatNumber(planPerDay.filter(item => item.name === ticket)[0].price) + " COP"*/}</div>
                </> :
                <>(Por Clases)</>

            }
            
        </div>
    </div>
    {
        openModal ? 
        <div className='shopping_modal_container'>
            <div className='shopping_modal'>
                {
                    planPerDay.map(item => (
                        <div className='shopping_modal_item' onClick={() => {
                            setOpenModal(false);
                            setTicket(item.name);
                            setPlanSelected("Tiquetera");
                        }}>
                            <div>{"Días: " + item.name}</div>
                            <div>{"$"  + formatNumber(item.price) + " COP"}</div>
                        </div>
                    ))
                }
            </div>
            
        </div> : <></>
    }
    <div className='schedule_buttons'>
        <div></div>
        <button className="signout_button_item" style={{marginRight: "20px"}} onClick={_handleSavePurchase}>
            {
                !loading ? "Enviar Solcitud" :  
                <Box sx={{ display: 'flex'}}>
                <CircularProgress size="16px" style={{color: "gray"}} />
                </Box>
            }
        </button>

    </div>
    

    <div></div>
    
    </>
    
  );
};

export default ShoppingComponent;
